<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
            <iq-card>
                <template v-slot:body>
                    <b-overlay :show="formLoader" rounded="sm">
                        <b-row>
                            <b-col lg="12" sm="12">
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                            <ValidationProvider name="Note" vid="note" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="3"
                                                label-for="crop_name_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{$t('content_management.note')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-textarea
                                                plain
                                                v-model="manage_gallery.note"
                                                :options="cropList"
                                                id="crop_name_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-textarea>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col text-right">
                                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                &nbsp;
                                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                            </div>
                                        </div>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { manageGalleryStoreApi } from '../../api/routes'

export default {
  props: ['id', 'status'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        formLoader: false,
        saveBtnName: this.$t('globalTrans.save'),
        manage_gallery: {
            id: this.id,
            note: '',
            note_type: this.status === 2 ? 1 : 2,
            status: this.status
        }
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    async saveData () {
        this.formLoader = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadinState = { loading: false, listReload: false }

        result = await RestApi.postData(virtualMuseumServiceBaseUrl, manageGalleryStoreApi, this.manage_gallery)
        loadinState.listReload = true

        this.$store.dispatch('mutateCommonProperties', loadinState)

        if (result.success) {
                this.formLoader = false
                this.$toast.success({
                title: 'Success',
                message: this.status === 2 ? this.$t('content_management.review_msg') : this.$t('content_management.reject_msg'),
                color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
        } else {
                this.formLoader = false
                this.$refs.form.setErrors(result.errors)
        }
    }
  }
}
</script>

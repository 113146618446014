
<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <!-- Add New -->
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('content_management.manage_gallery') + ' ' + $t('globalTrans.list')}}</h4>
          </template>
          <!-- /Add New -->
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">

                  <!-- Table -->
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>

                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>

                    <template v-slot:cell(gallery_image)="data">
                      <b-img v-if="data.item.image != null" fluid :src="data.item.gallery_image"  class="cover"/>
                    </template>
                     <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_edit" v-if="data.item.status==1" :title="$t('globalTrans.approve')" @click="approve(data.item)"><i class="fas fa-check"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_status" v-if="data.item.status==1" v-b-modal.modal-4 title="Review" @click="review(data.item)">
                        <i class="fas fa-share-square"></i>
                      </a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" v-if="data.item.status==4" v-b-modal.modal-4 :title="$t('globalTrans.reject')" @click="reject(data.item)">
                        <i class="fas fa-times"></i>
                      </a>
                      <!-- <span v-if="data.item.status==4">Approved</span> -->
                    </template>
                  </b-table>

                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-4" size="lg" :title="formTitle"  :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId"  :status="status" :key="editItemId"/>
    </b-modal>
  </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { manageGalleryListApi, manageGalleryStoreApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
     Form
  },
  data () {
    return {
      search: {
        org_id: this.$store.state.dataFilters.orgId ? this.$store.state.dataFilters.orgId : 0
      },
      editItemId: 0,
      status: '',
      rows: [],
      url: virtualMuseumServiceBaseUrl
    }
  },
  computed: {
    formTitle () {
        if (this.editItemId === 0) {
            return this.$t('content_management.manage_gallery') + ' ' + this.$t('globalTrans.entry')
        } else {
            return this.$t('content_management.manage_gallery') + ' ' + this.$t('globalTrans.modify')
        }
    },
    viewTitle () {
        if (this.editItemId === 0) {
            return this.$t('content_management.manage_gallery')
        } else {
            return this.$t('content_management.manage_gallery')
        }
    },
    orgList: function () {
        const objectData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    columns () {
      // Table Column Labels
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.organization'), class: 'text-center' },
          { label: this.$t('content_management.gallery_type'), class: 'text-center' },
          { label: this.$t('pest_management.image'), class: 'text-center' },
          { label: this.$t('content_management.description'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center fixed-width' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'gallery_type' },
          { key: 'gallery_image' },
          { key: 'description' },
          { key: 'approval_status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'gallery_type' },
          { key: 'gallery_image' },
          { key: 'description' },
          { key: 'approval_status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  created () {
    this.loadData()
  },
  watch: {
  },
  methods: {
    review (item) {
      this.editItemId = item.id
      this.status = 2
    },
    reject (item) {
      this.editItemId = item.id
      this.status = 3
    },
    searchData () {
      this.loadData()
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(virtualMuseumServiceBaseUrl, manageGalleryListApi, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    async approve (data) {
        this.formLoader = true
        const obj = {
                    id: data.id,
                    status: 4
                  }
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadinState = { loading: false, listReload: false }
        result = await RestApi.postData(virtualMuseumServiceBaseUrl, manageGalleryStoreApi, obj)
        loadinState.listReload = true

        this.$store.dispatch('mutateCommonProperties', loadinState)

        if (result.success) {
                this.formLoader = false
                this.$toast.success({
                title: 'Success',
                message: this.$t('content_management.approval_msg'),
                color: '#D6E09B'
                })
        } else {
                this.$refs.form.setErrors(result.errors)
        }
    },
    getRelationalData (data) {
            let orgList = {}
            let status = ''
            const listData = data.map(item => {
                if (item.status === 1) {
                    status = this.$t('content_management.pending')
                } else if (item.status === 2) {
                    status = this.$t('content_management.reviewed')
                } else if (item.status === 3) {
                    status = this.$t('content_management.rejected')
                } else {
                    status = this.$t('content_management.approved')
                }
                orgList = this.$store.state.commonObj.organizationProfileList.find(org => org.value === item.org_id)
                const data = {
                    org_name: orgList !== undefined ? orgList.text_en : '',
                    org_name_bn: orgList !== undefined ? orgList.text_bn : '',
                    gallery_type: item.type === 1 ? this.$t('content_management.crop') : this.$t('content_management.pest'),
                    gallery_image: item.image !== null ? virtualMuseumServiceBaseUrl + 'gallery-manage/' + item.image : '',
                    approval_status: status
                }
                return Object.assign({}, item, data)
            })
            return listData
    }
  }
}
</script>
<style lang="scss">
  .fixed-width {
    width: 180px !important;
  }
  .cover {
  width: 100px !important;
  height: 70px !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
